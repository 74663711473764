import axios from 'axios';
import {
    SET_API_ERRORS,
    SET_ALIASES,
    SET_CATALOG,
    SET_PAGE,
    SET_PAGE_START,
    SET_PRODUCT_LIST,
    SET_VIEWED_PRODUCTS,
    SET_CONTACTS,
    SET_HOME_GALLERIES,
    SET_ABOUT_PAGE,
    SET_PRODUCT_SERIES,
    SET_STATIC_PAGE,
    TOOGLE_LOGIN,
    SET_MENU,
    SET_PROMO_CODE,
    SET_COUNT_ORDERS,
    ADD_PRODUCT_LIST,
    SET_NOTIFICATION,
    SET_SOCIAL,
    SET_GOOGLE_REVIEWS,
    SET_DISCOUNT_AMOUNTS,
    SET_STORE_DATA,
    SET_CATALOG_CATEGORIES,
    SET_CALCULATE_CATEGORIES,
} from './types';
import { filterToString, historyToString} from "../functions/main";
import {store} from "../store";
import {handleApiError, sendPost} from "./admin";


const Url = process.env.REACT_APP_SERVER_URL || process.env.NEXT_PUBLIC_SERVER_URL;
const ApiServer = process.env.REACT_APP_API_SERVER_URL || process.env.NEXT_PUBLIC_API_SERVER_URL;
export const getAliases = () => dispatch => {
    return axios.get(Url+'/api/v1/getAliases')
        .then(res => {
            const aliases = res.data;
            dispatch(setAliases(aliases));
        })
        .catch(err => {
            console.log(err);
             dispatch({
                type: SET_API_ERRORS,
                payload: err
            })
        });
};
export const getStaticMenu = () => {
    return axios.get(Url+ApiServer+'/menu?sort=sort&active=1')
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return false;
        });


};
export const getStaticPageByAlias = (alias) => {
    return axios.get(`${Url+ApiServer}/get-static-page/${alias}`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};


export const getTermsGrouped = () => {
    return axios.get(`${Url+ApiServer}/terms/grouped`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};
export const getTerms = () => {
    return axios.get(`${Url+ApiServer}/terms`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

export const getTerm = (alias) => {
    return axios.get(`${Url+ApiServer}/terms/?slug=${alias}`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};



export const getPageAliases = () => {
    return axios.get(`${Url+ApiServer}/getPageAliases`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log('err',err.message);
            return [];
        });


};
export const getPageInfo = (alias) => dispatch => {

    let pageInfoUrl = alias;
    if(alias.url!==undefined){
        pageInfoUrl = pageInfoUrl.url.replace('/','');
        pageInfoUrl = pageInfoUrl.split('?')[0]
    }
    return axios.get(Url+ApiServer+'/getPageInfo?alias='+pageInfoUrl)
        .then(res => {
            const page = res.data;
            if(page.type==='Category'){
                let pageRes = dispatch(setPage(page));
                return dispatch(getProducts(alias));
            }else{
                if(page.type==='Product'){
                    if(page.result && page.result.product_series.length>0){
                        let pageRes = dispatch(setPage(page));
                        return dispatch(getSeries(page.result.product_series[0].master_product_id));
                    }else{
                        dispatch(setPage(page));
                    }
                }else{
                    if(page.type==='Page'){
                        dispatch(setPage(page));
                    }else{
                         dispatch(setPage(page));
                    }
                }
            }

        })
        .catch(err => {
            console.log(err);
             dispatch({
                type: SET_API_ERRORS,
                payload: err
            })
        });
};

export const getSeries = (id) => dispatch =>{
    console.log('id', id)
    let url = ApiServer+'/product_series/'+id;
    return  axios.get(Url+url)
        .then(res => {
            return dispatch(setProductSeries(res.data));
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};
export const getProducts = (alias, add = false) => dispatch => {
    let paramsQuery='';
    if(typeof document !== "undefined"){

        paramsQuery = historyToString(new URLSearchParams(window.location.search));
    }
    let productsUrl = alias;
    let limit = process.env.REACT_APP_PRODUCTS_LIMIT;
    let page = 1;

    let lineQuery = '';
    if ( alias && alias. query && alias.query.hasOwnProperty('line')) {
        lineQuery = alias.query.line;
        delete alias.query.line;
    }

    if(alias.url!==undefined){

        productsUrl = alias.url.replace('/','');
        if(alias.query.page){
            page = alias.query.page;
        }
        productsUrl = productsUrl.split('?')[0];
        let filtersUrl = filterToString(alias.query);
        if(filtersUrl && filtersUrl!==''){
            productsUrl+= '&'+filtersUrl;
        }
    }

    if (lineQuery) {
        productsUrl+= '&line='+lineQuery;
    }

    if(productsUrl.indexOf('limit') === -1){
        productsUrl+= '&offset='+((page-1)*limit);
        productsUrl+= '&limit='+limit;
    }
    if(productsUrl.indexOf('active') === -1){
        productsUrl+= '&active=1';
    }

    if(paramsQuery && paramsQuery!==''){
        if(productsUrl.indexOf(paramsQuery) === -1){
            productsUrl+= '&'+paramsQuery;
        }
    }
    if(productsUrl.indexOf('sort') === -1){
        productsUrl+= '&sort=sort';
    }
    return axios.get(Url+ApiServer+'/products?category=slug:'+productsUrl)
        .then(res => {
            console.log('add', add);
            if (add)
                dispatch(addProductList(res.data));
            else
                dispatch(setProductList(res.data));
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};

export const getCatalog = () => dispatch => {
   return  axios.get(Url+ApiServer+'/categories?active=1')
        .then(res => {
            let catalog = res.data;
            let disp = dispatch(setCatalog(catalog));
            return dispatch(getPage('/catalog'));
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};

export const getContacts = () => dispatch => {
   return  axios.get(Url+ApiServer+'/contacts')
        .then(res => {
            let contacts = res.data;

            let disp = dispatch(setContacts(contacts));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};
export const getSocial = () => dispatch => {
   return  axios.get(Url+ApiServer+'/settings/social')
        .then(res => {
            let contacts = res.data;

            let disp = dispatch(setSocial(contacts));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
        });
};

export const getStoreData = () => dispatch => {
    return  axios.get(Url+ApiServer+'/settings/store_data')
        .then(res => {
            let store_data = res.data;

            let disp = dispatch(setStoreData(store_data));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
        });
};


export const getHomeGalleries = () => dispatch => {
   return  axios.get(Url+ApiServer+'/galleries?page=home&sort=sort')
        .then(res => {
            let galleries = res.data;

            let disp = dispatch(setGalleries(galleries));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};

export const getCatalogCategories = () => dispatch => {
    return  axios.get(Url+ApiServer+'/categories?active=1')
        .then(res => {
            let categories = res.data;

            let disp = dispatch(setCatalogCategories(categories));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};

export const getCalculateCategories = () => dispatch => {
    return  axios.get(Url+ApiServer+'/calculate-categories')
        .then(res => {
            let categories = res.data;

            let disp = dispatch(setCalculateCategories(categories));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};

export const getCatalogGalleries = () => dispatch => {
   return  axios.get(Url+ApiServer+'/galleries?page=catalog')
        .then(res => {
            let galleries = res.data;

            let disp = dispatch(setGalleries(galleries));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};
export const getAboutPage = () => dispatch => {
   return  axios.get(Url+ApiServer+'/pages?slug=about')
        .then(res => {
            let result = res.data;

            let disp = dispatch(setAboutPage(result));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};
export const getMenu = () => dispatch => {
   return  axios.get(Url+ApiServer+'/menu?sort=sort&active=1')
        .then(res => {
            let result = res.data;
            let disp = dispatch(setMenu(result));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};
export const getCountOrders = () => dispatch => {
   return  axios.get(Url+ApiServer+'/orders/countOrders')
        .then(res => {
            let result = res.data;

            let disp = dispatch(setCountOrders(result));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};

export const getGoogleReviews = () => dispatch => {
   return  axios.get(Url+ApiServer+'/get-google-reviews')
        .then(res => {
            let result = res.data;

            let disp = dispatch(setGoogleReviews(result));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};

export const getDiscountAmounts = () => dispatch => {
   return  axios.get(Url+ApiServer+'/discount-by-amount?sort=amount')
        .then(res => {
            let result = res.data;

            let disp = dispatch(setDiscountAmounts(result));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};

export const getPromoCode = () => dispatch => {
   return  axios.get(Url+ApiServer+'/discount-codes/promo')
        .then(res => {
            let result = res.data;

            let disp = dispatch(setPromoCode(result));
            return disp;
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};


export const getHomePage = () => dispatch => {
    return  axios.get(Url+ApiServer+'/pages?slug=/')
        .then(res => {
            let result = res.data;
            return dispatch(setStaticPage(result));
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};
export const getPage = (slug) => dispatch => {
    return  axios.get(Url+ApiServer+'/pages?slug='+slug)
        .then(res => {
            let result = res.data;
            return dispatch(setStaticPage(result));
        })
        .catch(err => {
            console.log('error');
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err
            });
        });
};


export const formSubmit = (event, method, initialData, action) =>  dispatch => {

    method = method ? method : 'put';
    let url = action ? action : event.target.action;
    let data = event ?  new FormData(event.target) : [];
    let object = initialData ? initialData :{};
    data.forEach(function(value, key){
        object[key] = value;
    });

    let button =  (event.nativeEvent && event.nativeEvent.submitter) ? event.nativeEvent.submitter : event.target;
    let htmlTarget = button ? button.innerHTML:false;

    if(button){
        button.disabled = true;
        // button.innerHTML='Loading...';
    }


    if(data || initialData){

        return  axios({
            method: method,
            url: url,
            data: initialData||object,
            config: { headers: { 'Content-Type': 'application/json' }}
        }).then(result=>{
            if(button){
                // button.innerText = htmlTarget;
                button.disabled = false;
            }
            return result;
        })
            .catch(error => {
                if(button){
                    // button.innerText = htmlTarget;
                    button.disabled = false;
                }
                return dispatch(handleApiError(error))
            });

    }
    event.preventDefault();
};



export const setPageStart =  () => ({ type: SET_PAGE_START,  payload: true });


export const setAliases = aliases => {
    return {
        type: SET_ALIASES,
        payload: aliases
    }
};

export const setPage = page => {
    return {
        type: SET_PAGE,
        payload: page
    }
};
export const setStaticPage = staticPage => {
    return {
        type: SET_STATIC_PAGE,
        payload: staticPage
    }
};
export const setCatalog = catalog => {
    return {
        type: SET_CATALOG,
        payload: catalog
    }
};
export const setProductList = products => {

    return {
        type: SET_PRODUCT_LIST,
        payload: products
    }
};
export const addProductList = products => {

    return {
        type: ADD_PRODUCT_LIST,
        payload: products
    }
};
export const toogleLoginModal = (state) => {
    return {
        type: TOOGLE_LOGIN,
        payload: state
    }
};

export const setViewedProducts = () => {

    let viewed_products = false;
    if(typeof(Storage) !== "undefined"){
        viewed_products = JSON.parse(localStorage.getItem('viewed_products'));
    }

    return {
        type: SET_VIEWED_PRODUCTS,
        payload: viewed_products
    }
};


export const setContacts = contacts => {
    return {
        type: SET_CONTACTS,
        payload: contacts
    }
};

export const setSocial = items => {
    return {
        type: SET_SOCIAL,
        payload: items
    }
};
export const setStoreData = items => {
    return {
        type: SET_STORE_DATA,
        payload: items
    }
};
export const setGalleries = galleries => {
    return {
        type: SET_HOME_GALLERIES,
        payload: galleries
    }
};

export const setCatalogCategories = catalogCategories => {

    return {
        type: SET_CATALOG_CATEGORIES,
        payload: catalogCategories
    }
};

export const setCalculateCategories = calculateCategories => {

    return {
        type: SET_CALCULATE_CATEGORIES,
        payload: calculateCategories,
    }
};

export const setAboutPage = result => {
    return {
        type: SET_ABOUT_PAGE,
        payload: result
    }
};
export const setMenu = result => {
    return {
        type: SET_MENU,
        payload: result
    }
};
export const setCountOrders = result => {
    return {
        type: SET_COUNT_ORDERS,
        payload: result
    }
};
export const setGoogleReviews = result => {
    return {
        type: SET_GOOGLE_REVIEWS,
        payload: result
    }
};
export const setProductSeries = result => {
    return {
        type: SET_PRODUCT_SERIES,
        payload: result
    }
};
export const setDiscountAmounts = result => {
    return {
        type: SET_DISCOUNT_AMOUNTS,
        payload: result
    }
};
export const setPromoCode = result => {
    return {
        type: SET_PROMO_CODE,
        payload: result
    }
};
export const getInfoByUrl = (url) => dispatch => {
    return  axios.get(url)
        .then(res => {
            return res.data;

        })
        .catch(err => {
            console.log('error');
            console.log(err);
            return err;
        });
};


export const  savePlannerProject= (project,cabinet,customer_id)=>{
    console.log('project',project);
        let url = Url+ApiServer+'/customer-planner-projects/customer';
        if(customer_id){
            url+=`?customer_id=${customer_id}`
        }

        return store.dispatch(sendPost(url,{
            cabinet_name:cabinet.name,
            cabinet_link:cabinet.link,
            project_link:project.url,
            project_id:project.id,
            name:project.name
        },"PUT"));

};

export const setNotification = (message, type = false, timeout = 3000) => {
    if (message === false)
        return {
            type: SET_NOTIFICATION,
            payload: message
        }

    let obj = {};
    obj['message'] = message;
    obj['type'] = type;
    obj['timeout'] = timeout;

    return {
        type: SET_NOTIFICATION,
        payload: obj
    };
};
