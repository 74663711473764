import {store} from "../store";
import {setKit,setType} from "../actions/kit";


export const setKitType = (itemType) => {
    store.dispatch(setType({itemType: itemType}));

};

export const setKitItem = (muttableKitItem) => {
    let kitItem = JSON.parse(JSON.stringify(muttableKitItem));

    if(kitItem.customDimensions && kitItem.customDimensions.length){

        kitItem.customDimensions = kitItem.customDimensions.map((dimension)=>{
            dimension.value = dimension.value ? dimension.value : dimension.mm;
            dimension.label  =
                (dimension.inch ?
                    (dimension.inch[0] ? dimension.inch[0] : '') + (dimension.inch[1] ? ' '+dimension.inch[1]+'/'+dimension.inch[2]+'"' : '')
                    :'' )
                + ' ('+dimension.mm+' mm)';
            return dimension;
        });
        kitItem.customDimensions = groupArrayBy(kitItem.customDimensions,'name')
    }

    return store.dispatch(setKit({kitItem: kitItem}));
};
export const groupBy = (xs, key, push=true) => {
    return xs.reduce(function(rv, x) {
        if(push){
            (rv[x[key]] = rv[x[key]] || []).push(x);
        }else{
            rv[x[key]] = x;
        }
        return rv;
    }, {});
};
export const arrayObjectsMap = (xs, key, value) => {
    return xs.reduce(function(rv, x) {
        rv[x[key]] = x[value];
        return rv;
    }, {});
};
export const findObjectInArrayByKey = (array, key, value) => {
    for(let i in array){
        if(array[i].hasOwnProperty(key) && array[i][key]===value){
            return  array[i];
        }
    }
};

export const groupArrayBy = (xs, key, innerKey) => {

    if(innerKey){
        let newArr= [];
        for(let i  in xs){
            newArr.push(xs[i][innerKey].reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {}))
        }
        return newArr;
    }

    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
export const groupArrayByNestedObjKey = (xs, key, innerKey,noGroupedKey='noGrouped') => {
    return xs.reduce(function(rv, x) {
        if(x[innerKey] && x[innerKey][key]){
            (rv[x[innerKey][key]] = (x[innerKey] && x[innerKey][key] && rv[x[innerKey][key]]) ? rv[x[innerKey][key]] :  []).push(x);
            return rv;
        }else{
            (rv[noGroupedKey] = rv[noGroupedKey] ||  []).push(x)
            return rv;
        }
    }, {});

};
export const groupGalleryImages = (xs, key,secondKey='id') => {
    let res = [];
    xs.map(function(curObj, index) {
        // console.log(rv[x[key]]);
        let arrRes = curObj;
        let id = curObj[key].match(/\d/g);
        let type = curObj[key].match(/main|small/g);
        id = (id && id[0]) ? parseInt(id[0]) : id;
        type = (type && type[0]) ? type[0] : id;
        arrRes['id'] = id;
        arrRes['type'] = type;
        return res.push(arrRes);
    }, {});

    return groupBy(res,secondKey);
};
export const groupImages = (xs, key) => {
    // console.log(xs);
    let res = [];
    xs.map(function(curObj, index) {
        // console.log(rv[x[key]]);
        let arrRes = curObj;
        let id = curObj[key].match(/\d/g);
        let type = curObj[key].match(/main|small/g);
        id = (id && id[0]) ? parseInt(id[0]) : id;
        type = (type && type[0]) ? type[0] : id;
        arrRes['id'] = id
        arrRes['type'] = type;
        return res.push(arrRes);
    }, {});

    return groupBy(res,'type');
};
export const filterToString = (filters) => {
    if(filters){
        let str = [];
        for (let p in filters){
            if (filters.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filters[p].values));
            }
        }
        return str.join("&");
    }
    return '';
};

export const historyToString = (params) => {
    let filters = [];
    for( let p of params.entries()) {
            filters.push(encodeURIComponent(p[0]) + "=" + encodeURIComponent(p[1]));
    }
    return filters.join("&");
};

export const paramsToQuery=(params)=>{
    let query = [];
    for( let p of Object.keys(params)) {
        query.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }
    return query.join("&");
}


export const locationSearchToArray = (search) => {
    return   new URLSearchParams(search);
};


export const filtersToHistory = (filters,location,history) => {
    if(filters){
        const params = new URLSearchParams(location.search);
        let page = params.get('page');
        let newParams =  new URLSearchParams();
        if(page && page !== null){
            newParams.set('page',page);
        }
        for (let p in filters){
            if (filters.hasOwnProperty(p)) {
                newParams.set(p,filters[p].values);
            }
        }
        let newUrl = `${location.pathname}?${newParams}`;
        history.push( newUrl);
    }else{
        const params = new URLSearchParams(location.search);
        let page = params.get('page');
        let newParams =  new URLSearchParams();
        if(page && page !== null){
            newParams.set('page',page);
        }
        let newUrl = `${location.pathname}?${newParams}`;
        history.push( newUrl);
    }
    return filters;
};


export const checkActive = (filtersState,value_item) => {
    let active = false;
    if(typeof filtersState[value_item['product_property.slug']] !== 'undefined'){
        let checkArray =  filtersState[value_item['product_property.slug']];

        for(let i in checkArray.values ){
            if(checkArray.values[i] === value_item.slug){
                active = true;
                break;
            }
        }
    }
    return active;
};

export const getFilterName = (slug,properties) => {
    let name = false;

    for(let i in properties){
        if(properties[i]['product_property.slug'] && properties[i]['product_property.slug'] === slug){
            name = properties[i]['product_property.name'];
            break;
        }
    }
    return name;
};
export const filterObject = (obj,predicate) => {
    if(Array.isArray(obj)){
        return obj;
    }
    let result = {}, key;

    for (key in obj) {
        if (obj.hasOwnProperty(key) && predicate(obj[key])) {
            result[key] = obj[key];
        }
    }

    return result;
};
export const groupByLimit = (array,limit) => {

    let newArray = {};
    let numb = 1;
    for (let i=1; i<= array.length; i++){
        if( newArray[numb] === undefined ){
            newArray[numb] = [array[i-1]]
        } else {
          newArray[numb].push(array[i-1]);
        }
        if( (i%limit) === 0){
            numb++;
        }
    }
    return newArray;
};

export const groupObjectByLimit = (obj,limit) => {

    let newArray = {};
    let numb = 1;
    for (let i in Object.keys(obj)){

        if( newArray[numb] === undefined ){
            newArray[numb] = [{[Object.keys(obj)[i]]:obj[Object.keys(obj)[i]]}]
        } else {
          newArray[numb].push({[Object.keys(obj)[i]]:obj[Object.keys(obj)[i]]});
        }
        if( ((parseInt(i)+1)%limit) === 0){
            numb++;
        }
    }
    return newArray;
};


export const filterKeys = (obj,keys) => {
    let newObj = {};

    for(let i in Object.keys(obj)){
        if(keys.includes(Object.keys(obj)[i])){
            newObj[Object.keys(obj)[i]] = obj[Object.keys(obj)[i]];
        }
    }
    return newObj;
};

export const keyLengthExists = (array,key) => {
    let newArray = [];

    for(let i in array){
        if(array[i][key] && array[i][key].length>0){
            newArray.push(array[i])
        }
    }
    return newArray;
};


export const formatDate = (date) => {
    date = new Date(date);
    let hours = date.getUTCHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let miliseconds = date.getMilliseconds();
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ':' + seconds + ':' + miliseconds;
    return date.getDate()+ "-" + date.getMonth()+1  +  "-" + date.getFullYear() + ":" + strTime;
};


export const filterImageFilename = (filename) => {

    return filename.replace(/[^a-z\\0-9\.]/gi,"_");
};

export const generateSlug = (name) => {

    return name ? name.replace(/[^a-z\\0-9\.]/gi,"-").replace(/-+/g,"-").toLowerCase() : false;
};
export const arrayObjKeyExists = (array,key) => {
    let res = false;

    array.forEach(function (item) {
        item = item.id? item.id : item;
        if(item ===key){
            res =  true;
        }

    });
    return res;
};


export const arrayMoveMutate = (array, from, to) => {
    const startIndex = to < 0 ? array.length + to : to;
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
};

export const arrayMove = (array, from, to) => {
    array = array.slice();
    arrayMoveMutate(array, from, to);
    return array;
};

export const mergeArrayOfObjectsUnicByKey = (arr1, arr2,key) => {
    const mergedArray = [...arr1, ...arr2];
// mergedArray have duplicates, lets remove the duplicates using Set
    let set = new Set();
    return  mergedArray.filter(item => {
        if (!set.has(item[key])) {
            set.add(item[key]);
            return true;
        }
        return false;
    }, set);
};


export const stringMatchLike = (string, regex)=> {
    if(undefined===regex || typeof(regex)!='string') return false;

    function replacer(str, p1, p2) {
        if(p1==='\\') return p2;
        if(p2==='*') return p1+'.{0,99}';
        return p1+'\\w';
    }

    let pattern = "^"+regex.replace(/(.|^)([*_])/gm, replacer)+'$';
    let re = new RegExp(pattern, 'mg');
    return !!string.match(re);

};

export const convertFromInchState=(inchstate)=>{

    let coefficient = 25.4;
    let newDistance;
    if(!inchstate[1] || inchstate[1]==='' || !inchstate[2] || inchstate[2]===''){
        return ((inchstate[0] )*coefficient).toFixed();
    }
    newDistance = (parseFloat(inchstate[0]) + (parseFloat(inchstate[1])/parseFloat(inchstate[2])))*coefficient;

    return newDistance.toFixed();
};
export const convertMmToInch=(mm,fixed=0)=>{

    let coefficient = 25.4;


    return (mm/coefficient).toFixed(fixed)
};
export const isJsonString=(str)=>{
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
export const orderProductToCart=(product)=>{
    let calcOptionState={};
    calcOptionState.materials = {};
    calcOptionState.dimension = {
        height:false,
        width:false,
        depth:false,
    };
    calcOptionState.in = {};
    if(product.order_kit &&     product.order_kit.order_kit_fields){
        let orderKitFields = [...product.order_kit.order_kit_fields];
        orderKitFields.forEach((kitField)=>{
            let value = kitField.value;
            if(kitField.value){
                if(kitField.type && kitField.type==='product'){
                    calcOptionState.materials[kitField.name] = {
                        product_id:kitField.erp_id,
                        name:value
                    }
                }else{
                    if((kitField.name==='height'|| kitField.name==='width' || kitField.name==='depth') && kitField.value && parseInt( kitField.value) !==null){
                        calcOptionState.dimension[kitField.name]=(value && typeof value !== 'object' && value.toString().indexOf(',')!==-1)?'in':'mm';
                        if(value && typeof value !== 'object' && value.toString().indexOf(',')!==-1){
                            value = value.split(',');
                            calcOptionState.in[kitField.name] = value;
                            value = convertFromInchState(value);
                        }else{
                            if(isJsonString(value)){
                                value = JSON.parse(value);
                            }
                        }

                        calcOptionState[kitField.name] = value;
                    }else{
                        calcOptionState[kitField.name] = value;
                    }
                }
            }
        })
    }
    return calcOptionState;
};
export const convertDimensionToFront=(value)=> {
    if(isJsonString(value)){
        value = JSON.parse(value);
    }
    if(value && typeof value ==='object' && !Array.isArray(value)){
        value =value[Object.keys(value)[0]];

    }else{
        if(value && typeof (value) ==='string' &&  value.indexOf(',') !==-1){
            value = value.split(',');

            if(!value[1] || value[1]==='' || !value[1] || value[1]===''){
                value =  value[0] + '"';
            }else{
                value =  value[0]+ ' '+value[1] + '/'+value[2]+'"' ;
            }
        }else{
            value = value+' '+'MM';
        }
    }

    return value;
};



export const copyObject=(object)=> {
    let output, value, key;
    output = Array.isArray(object) ? [] : {};
    for (key in object) {
        value = object[key];
        output[key] = (typeof value === "object") ? copyObject(value) : value;
    }
    return output;
};

export const uniqId = ()=> {
    return Math.random().toString(36).substr(2, 9);
};



export const clearSpaces = (str)=> {
    return str ? str.replace(/\s/g, ''): str;
};

export const arrayObjectPropertySum =(array,prop)=>{
    let total = 0;
    for ( var i = 0, _len = array.length; i < _len; i++ ) {
        total += array[i][prop]
    }
    return total
};

export const compareObjectKeysValues =(obj1,obj2,excludeKeys=[])=>{
    for(let p in obj1){

        // console.log('key',p);

        if(excludeKeys.includes(p)){
            continue;
        }

        if(obj1.hasOwnProperty(p)){
            if(obj1[p] !== obj2[p]){
                // console.log('p',p,obj1[p],obj2[p]);
                return false;
            }
        }
    }
    for(let p in obj2){
        // console.log('key',p);
        if(excludeKeys.includes(p)){
            continue;
        }

        if(obj2.hasOwnProperty(p)){
            if(obj1[p] !== obj2[p]){
                // console.log('p2',p,obj1[p],obj2[p]);
                return false;
            }
        }
    }
    return true;
};

export const getMMFromCustomDimensions =(value)=>{
    /**
     * Match mm from custom dimension string
     * @type {RegExp}
     */
    let re  = /\((\d{2,3})(\s|\\mm)|\\mm\)/;
    let dimensionMath = value[Object.keys(value)[0]].match(re);
    if(dimensionMath && dimensionMath[1]){
        value= parseFloat(dimensionMath[1]);
    }
    return value;
}
export const compare=( a, b,key,innerKey )=>{
    if (a[key] && b[key] &&  a[key][innerKey] < b[key][innerKey]){
        return -1;
    }
    if (a[key] && b[key] &&  a[key][innerKey] > b[key][innerKey]){
        return 1;
    }
    return 0;
}

export const arrayDivide=(array,chunk=10)=>{
    let i,j,tempArr;
    let resArr = [];
    for (i=0,j=array.length; i<j; i+=chunk) {
        tempArr = array.slice(i,i+chunk);
        resArr.push(tempArr)
    }
    return resArr;
};
export const arrayObjMinValue=(array=[],key)=>{
    if(!array || !array.length)
        return false;

    let minValue = array[0][key];
    let minItem = array[0];
    for (let i = 1; i < array.length; i++) {
        let v = array[i][key];
        if(parseInt(v) < parseInt(minValue)){
            minValue = v;
            minItem = array[i];
        }
    }
    return minItem;
};
export const arrayObjMaxValue=(array=[],key)=>{
    if(!array || !array.length)
        return false;

    let minValue = array[0][key];
    let minItem = array[0];
    for (let i = 1; i < array.length; i++) {
        let v = array[i][key];
        if(parseInt(v) > parseInt(minValue)){
            minValue = v;
            minItem = array[i];
        }
    }
    return minItem;
};
export const urlParamsToObject=(url)=>{
    if(url.indexOf('?')!==-1){
        url = url.split('?')[1]
    }

    let searchParams = new URLSearchParams(url);
    let entries = searchParams.entries();
    const result = {}
    for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
};


export const  mminch =(input,returnArray=false)=> {
    var TO_FRACTION_64 = 0.015625;
    var TO_FRACTION_16 = 0.0625;
    var MM_TO_INCH = 25.4
    var INCH_TO_FEET = 12

    var simplifyFraction = function (numerator, _denominator) {
        var denominator = _denominator || 64
        // if there is no denominator then
        // there is no fraction
        if (numerator < 1) {
            return ''
        }
        // fraction can't be broken further down:
        if (
            // a) if numerator is 1
            numerator === 1 ||
            // b) if numerator is prime number
            !((numerator % 2 === 0) || Math.sqrt(numerator) % 1 === 0)
        ) {
            return numerator + '/' + denominator
        }

        var newNumerator = numerator / 2
        var newDenominator = denominator / 2
        return simplifyFraction(newNumerator, newDenominator)
    };

    function toInch (_input) {
        var rawInches = (Number(_input || input) / MM_TO_INCH);
        // integers
        var integers = Math.floor(rawInches);
        // limit to 6 decimals to avoid conflicts
        var decimals = Number((rawInches % 1).toFixed(6));
        // fractionize for denominator 64
        var fraction64 = Math.round(decimals / TO_FRACTION_16);
        var simplifiedFraction = simplifyFraction(fraction64,16);
        var result = [integers, simplifiedFraction];
        result =  result.filter(function (r) { return r }).join(' ');

        return returnArray ?[integers,simplifiedFraction.split('/')[0],simplifiedFraction.split('/')[1]] : result
    }

    function toMM () {
        // should take numbers or strings
        var stringifiedInput = input + ''
        var fragments = stringifiedInput.split(' ')
        var inchesAndDecimals = fragments.map(function (fragment) {
            var broken = fragment.split('/')
            if (broken.length === 2) {
                // Strip the leading 0
                var decimals = (Number(broken[0]) / Number(broken[1])).toFixed(6)
                return decimals.slice(1)
            }
            return Number(broken[0])
        }).join('')

        // convert to mm
        var mm = Number(inchesAndDecimals) * MM_TO_INCH

        // return exact rounding if it is the 1:1 ratio
        // so the user doesn't freaks out of the conversion
        if (mm % MM_TO_INCH === 0) {
            return (Math.round(mm * 10) / 10) + ''
        }
        // round to the nearest half so it matches the toInch()
        // and return as string
        return (Math.round(mm * 2) / 2) + ''
    }

    function toFeet () {
        // parse to MM
        var mm = toMM()
        var inches = Math.round(mm / MM_TO_INCH)
        var feet = Math.floor(inches / INCH_TO_FEET)
        var stringFeet = feet + ' ft'
        var residualInches = Math.round(inches % INCH_TO_FEET)
        var stringInches = residualInches === 0 ? '' :
            ' ' + residualInches + ' in'

        if (!feet) {
            return stringFeet + ' ' + toInch(inches || 1) + ' in'
        }
        return stringFeet + stringInches
    }

    return {
        toInch: toInch,
        toFeet: toFeet,
        toMM: toMM
    }
};
