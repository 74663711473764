
import {SET_API_ERRORS, SET_ADD_TO_CART_BUTTON_TEXT, SET_CART_PRODUCTS, SET_GST,SET_SHIPPING,SET_ADMIN_SAVED_CART} from './types';
import {store} from "../store";
import {filterKeys} from "../functions/main";
import {getInfo} from "./admin";
import {setGtmEvent} from "../functions/GtmDataLayer";

const VANITIES_ID = 45;

export const getGst= () => dispatch => {
    const ApiUrl = process.env.REACT_APP_API_SERVER_URL;
    let url =  ApiUrl+'/orders/customer/gst';

    store.dispatch(getInfo(url))
        .then(res=>{
            if(res.errors){
                console.log(res.errors);
            }else{
                if(res && res.value)
                    dispatch(setGst(res.value));
            }
        })
        .catch(err=>{
            dispatch({
                type: SET_API_ERRORS,
                payload: err.response ? err.response.data : err
            });

        });
};
export const getShipping= (order) => dispatch => {
    const ApiUrl = process.env.REACT_APP_API_SERVER_URL;

    let url =  ApiUrl+'/orders/customer/shipping-price';
    if(order&&order.id){
        url+='?order_id='+order.id;
    }
    return dispatch(getInfo(url))
        .then(res=>{
            if(res.errors){
                console.log(res.errors);
                return dispatch({
                    type: SET_API_ERRORS,
                    payload: res.errors
                });
            }else{
                if(res && res[0] && res[0].provider){
                    return dispatch(setShipping(res));
                }
            }
        })
        .catch(err=>{
            return dispatch({
                type: SET_API_ERRORS,
                payload: err.response ? err.response.data : err
            });

        });
};


export const setAddToCartButtonText = (text) => {
    return {
        type: SET_ADD_TO_CART_BUTTON_TEXT,
        payload: text
    }
};
export const setGst = (gst) => {
    return {
        type: SET_GST,
        payload: gst
    }
};
export const setShipping = (shipping) => {
    return {
        type: SET_SHIPPING,
        payload: shipping
    }
};
export const setAdminSavedCart = (payload) => {

    localStorage.setItem('adminSavedCardCreate', JSON.stringify(payload));

    return {
        type: SET_ADMIN_SAVED_CART,
        payload: payload
    }
};
export const setCartProducts = () => {

    let cart_products = false;
    let adminSavedCardCreate = false;
    if(typeof(Storage) !== "undefined"){
        cart_products = JSON.parse(localStorage.getItem('cart_products'));
        adminSavedCardCreate = JSON.parse(localStorage.getItem('adminSavedCardCreate'));
    }

    let total = 0;
    let quantityTotal = 0;

    if(cart_products && cart_products.length>0){
        cart_products.map(product=>{
                total+=(product.price_new? product.price_new : product.price)*product.quantity;
                quantityTotal+=product.quantity;
                return product;
        }
        );
        if(adminSavedCardCreate){
            store.dispatch(setAdminSavedCart(adminSavedCardCreate));
        }
    }



    return {
        type: SET_CART_PRODUCTS,
        payload: {products:cart_products,quantityTotal:quantityTotal,total:total}
    }
};


export const deleteProduct = (id) => {
    let cart_products = false;
    if(typeof(Storage) !== "undefined"){
        cart_products = JSON.parse(localStorage.getItem('cart_products'));
    }
    for(let i in cart_products){
        if(cart_products[i].id === id){

          /*  removeProductToDataLayer(cart_products[i]);*/

            cart_products.splice( i, 1 );
            break;
        }
    }

    localStorage.setItem('cart_products', JSON.stringify(cart_products));
    return store.dispatch(setCartProducts());

};

export const clearCart = (merchant = false) => {
    console.log('clearCart')
    if(typeof(localStorage) !== "undefined"){

        let cart_products = JSON.parse(localStorage.getItem('cart_products'));

        if (cart_products) {
            if (merchant) {
                cart_products = cart_products.filter(product => product.category_id !== VANITIES_ID);
            } else {
                cart_products = cart_products.filter(product => product.category_id === VANITIES_ID);
            }
        }

        if (merchant.length > 0) {
            localStorage.setItem('cart_products', JSON.stringify(cart_products));
            localStorage.setItem('adminSavedCardCreate', JSON.stringify(cart_products));
        } else {
            localStorage.removeItem('cart_products');
            localStorage.removeItem('adminSavedCardCreate');
        }
    }
    store.dispatch(setAdminSavedCart(false));

    return store.dispatch(setCartProducts());

};

export const setQuantity = (product,quantity) => {
    let cart_products = false;
    if(typeof(Storage) !== "undefined"){
        cart_products = JSON.parse(localStorage.getItem('cart_products'));
    }
    for(let i in cart_products){

        if(cart_products[i].id === product.id){
            let event = quantity < cart_products[i].quantity ? 'remove_from_cart' : 'add_to_cart';
            let difference = Math.abs(product.quantity - quantity);

            cart_products[i].quantity = quantity;

            setGtmEvent(event, product);

            if(quantity<=0 && quantity!==''){
                cart_products.splice( i, 1 );
            }

            break;
        }
    }

    localStorage.setItem('cart_products', JSON.stringify(cart_products));
    return store.dispatch(setCartProducts());

};

export const addProductToCart = (product, quantity,miniCart) => {

    let cart_products = false;
    if(typeof(Storage) !== "undefined"){
        cart_products = JSON.parse(localStorage.getItem('cart_products'));
    }
    if(typeof cart_products == 'undefined' || cart_products ==null){
        cart_products = [];
    }


    let exist = false;
    for(let i in cart_products){
        if(cart_products[i].id === product.id){
            if(miniCart){
                cart_products[i] = product;
                cart_products[i].quantity = quantity;
                exist = true;
            }else{

                cart_products[i].quantity += quantity;
                exist = true;
            }
            break;
        }
    }

    if (product.sample_id) {
        setGtmEvent('view_item', product);
    }

    setGtmEvent('add_to_cart', product);

    if(!exist){
        product.quantity = quantity;

        product = filterKeys(product, [
            'id', 'name', 'slug', 'system_files', 'code','quantity', 'price', 'price_new', 'kit', 'fields','sample_id', 'product_id', 'image', 'product_property_values','addons', 'category_id'
        ]);

        cart_products.push(product);
    }

    localStorage.setItem('cart_products', JSON.stringify(cart_products));
    return store.dispatch(setCartProducts());
};

const removeProductToDataLayer=(product)=>{
    if(typeof window !=='undefined' && window.dataLayer) {
        // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
        // and a list of productFieldObjects.
        //            'id', 'name', 'slug', 'system_files', 'code','quantity', 'price', 'price_new', 'kit', 'fields','sample_id', 'product_id', 'image', 'product_property_values'
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {                                // 'add' actionFieldObject measures.
                    'products': [{                        //  adding a product to a shopping cart.
                        'name': product.name,
                        'id': product.id,
                        'price':  product.price,
                        'category': product?.kit?.calcCategory?.name,
                        'quantity': product.quantity
                    }]
                }
            }
        });
    }
};
