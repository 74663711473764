const dataLayerExists = typeof window !=='undefined' && window.dataLayer;
const ApiUrl = process.env.REACT_APP_API_SERVER_URL;
const ErpUrl = process.env.REACT_APP_API_URL;
const tax = 5;

function setGtmEvent(event, product, shipping = false, order_id) {
    clearDataLayer();

    if (product) {
        let items = product.hasOwnProperty('name') ? [getDataLayerProduct(product)] : getDataLayerProductsArray(product);
        let value = product.hasOwnProperty('name') ? product.price : getValue(product);

        if (dataLayerExists) {
            let object = {
                event: event,
                ecommerce: {
                    currency: "CAD",
                    value: value,
                    items: items,
                }
            };

            if (shipping) {
                object['ecommerce']['shipping_tier'] = shipping;
            }

            if (order_id) {
                let order = getOrderData(order_id);
                object['ecommerce']['transaction_id'] = order.id;
                object['ecommerce']['value'] = order.subtotal_price;
                object['ecommerce']['shipping_price'] = order.shipping_price;
                object['ecommerce']['currency'] = order && order.currency ? order.currency.name : 'CAD';
                object['ecommerce']['tax'] = getGst(order.subtotal_price);

                let coupon = getDiscountCode(order.order_discounts);
                if (coupon) {
                    object['ecommerce']['coupon'] = coupon;
                }

            }

            window.dataLayer.push(object);

            console.log('GTM', window.dataLayer);

        }
    }
}

function setGtag(event, product, value, order_id = false) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': `${event}`,
        'send_to': 'AW-623430533',
        'value': `${value}`,
        'items': [{
            'id': `${product.code}`,
            'google_business_vertical': 'retail'
        }]
    });
    console.log('gtag ' + event);
}

function clearDataLayer() {
    if(dataLayerExists) {
        window.dataLayer.push({ecommerce: null});
    }
}

function getValue(products) {
    let sum = 0;
    products.map(product => {
            sum += product.price;
        });
    return sum;
}

function getGst(subtotal) {
    return subtotal / 100 * tax;
}

function getDiscountCode(discounts) {
    let coupon = false;

    if (discounts && discounts.length > 0) {
        discounts.map(discount => {
           if (discount.type === 'discount_by_code' && discount.discount_code) {
               coupon = discount.discount_code;
           }
        });
    }
    return coupon;
}

function getCategory(product) {

    if (product.sample_id && product.group)
        return 'SAMPLE: ' + product.group.name;

    if (product.sample_id && !product.group)
        return 'SAMPLE: ' + getSampleCategory(product.sample_id);

    if (product.kit && product.kit.calcCategory)
        return product.kit.calcCategory.name;

    if (product.kit && product.kit.name)
        return product.kit.name;

    if (product.product_category)
        return product.product_category.name;

    return getProductCategoryName(product.id);
}

function getSampleCategory(sample_id) {
    let result = new XMLHttpRequest();
    result.open('GET', ErpUrl + '/kit/api/product-attribute-values?fields=name,slug,use_as_image_in_calc,calc_clear&expand=products&filter[slug]=sample', false);
    result.send();

    let data = JSON.parse(result.response);

    if (data) {
        let sample = searchSample(data.products, sample_id);
        return sample.group.name;
    }
}

function searchSample(products, product_id) {
        for (let i = 0; i < products.length; i++) {
            if (products[i].product_id === product_id) {
                return products[i];
            }
        }
        return null;
}

function getProductCategoryName(productId) {
    let request = new XMLHttpRequest();
    request.open('GET', ApiUrl + '/categories/get-product-category/' + productId, false);
    request.send();

    return request.status === 200 ? request.responseText : false;
}

function getOrderData(orderId) {
    let request = new XMLHttpRequest();
    request.open('GET', ApiUrl + '/get-order-data/' + orderId, false);
    request.send();

    return request.status === 200 ? JSON.parse(request.responseText) : false;
}

function getDataLayerProductsArray(products) {
    return (Array.isArray(products)) ? products.map(product => {
        return getDataLayerProduct(product);
    }) : false;
}

function getDataLayerProduct(product) {
    return {
        'item_name': product.name,
        'item_id': product.id,
        'price': product.price,
        'item_category': getCategory(product),
        'quantity': product.quantity ? product.quantity : 1,
    }
}

export {setGtmEvent, setGtag}