import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { store } from './store';
import { setViewedProducts } from './actions/init';
import { setCartProducts } from './actions/cart';
import { setCustomerFromLocalStorage, setUserFromLocalStorage } from './actions/authentication';
import { getCurrencies } from './actions/currencies';
import routes from './routes';
import Header from './components/site/modules/Header';
import Footer from './components/site/modules/Footer';

const PrivateRoute = loadable(() => import('./PrivateRoute'));
const Admin = loadable(() => import('./admin/App'));
const Login = loadable(() => import('./admin/Login'));
store.dispatch(setViewedProducts());
store.dispatch(setCartProducts());
store.dispatch(setUserFromLocalStorage());
store.dispatch(setCustomerFromLocalStorage());
store.dispatch(getCurrencies());

require('dotenv').config();

function App() {
    const [userActionExecuted, setUserActionExecuted] = useState(false);

    useEffect(() => {
        const userActionHandler = () => {
            setUserActionExecuted(true);
        };

        document.addEventListener('mousemove', userActionHandler, { once: true });
        document.addEventListener('scroll', userActionHandler, { once: true });
        document.addEventListener('touchstart', userActionHandler, { once: true });

        return () => {
            document.removeEventListener('mousemove', userActionHandler);
            document.removeEventListener('scroll', userActionHandler);
            document.removeEventListener('touchstart', userActionHandler);
        };
    }, []);

    return (
        <Switch>
            <PrivateRoute path="/admin" component={Admin} />
            <Route exact path="/login" component={Login} />
            <Route
                render={({ location }) => (
                    <div>
                        <Header />
                        <div id="wrapper">
                            <Route
                                render={({ location }) => (
                                    <Switch location={location}>
                                        {routes.map((route) => {
                                            let compProps = route.props !== undefined ? route.props : {};
                                            let RouteComponent = route.component;
                                            return (
                                                <Route
                                                    key={route.path}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    render={(routeProps) => <RouteComponent {...routeProps} {...compProps} />}
                                                />
                                            );
                                        })}
                                    </Switch>
                                )}
                            />
                        </div>
                        {location.pathname.indexOf('/account-information') === -1
                        && location.pathname.indexOf('/calc') === -1
                        && location.pathname.indexOf('/cart') === -1
                        && location.pathname.indexOf('/terms') === -1
                        && (location.pathname.indexOf('/kitchen') === -1 || location.pathname === '/catalog/kitchen_accessories')
                        && userActionExecuted ? <Footer /> : ''}
                    </div>
                )}
            />
        </Switch>
    );
}

export default App;
