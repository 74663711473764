import axios from 'axios';
import {
    SET_API_ERRORS,
    SET_API_STATUS_CODE,
    SET_TABLE_INFO,
    SET_FORM_INFO,
    SET_VIEW_INFO,
    SET_NOTIFICATION, SET_PARTNERS
} from './types';
import jwt_decode from "jwt-decode";




const Url = process.env.REACT_APP_SERVER_URL;


export const getInfo = (url,config={}) => dispatch => {
    return  axios.get(Url+url,config)
        .then(res => {

            return res.data;

        })
        .catch(error => dispatch(handleApiError(error)));

};




export const getTableInfo = (url) => dispatch => {
   return  axios.get(Url+url)
        .then(res => {
            let info = res.data;

            dispatch(setTableInfo(info));

        })
       .catch(error => dispatch(handleApiError(error)));

};
export const getViewInfo = (url) => dispatch => {
   return  axios.get(Url+url)
        .then(res => {
            let info = res.data;
            dispatch(setViewInfo(info));

        })
       .catch(error => dispatch(handleApiError(error)));
};


export const getFormInfo = (url) => dispatch => {
    return  axios.get(Url+url)
        .then(res => {
            let info = res.data;
            dispatch(setFormInfo(info));

        })
        .catch(error => dispatch(handleApiError(error)));
};

export const deleteItem = (url) => dispatch => {
    return  axios.delete(Url+url);
};

export const updateItem =  (event,target) => dispatch => {

    target = target ? target : event.target;

    let button=false;
    let innerText = false

    if(event && event.nativeEvent){
        button = (event.nativeEvent && event.nativeEvent.submitter) ? event.nativeEvent.submitter : (event.target.tagName.toLowerCase()==='button' ? event.target : false);
        if(button){


            innerText = button.innerText;
            button.innerText = 'Loading...';

            button.disabled = true;
        }
        event.persist();
    }



    let url = target.action;
    let data = new FormData(target);
    let object = {};
    data.forEach(function(value, key){
        value = (value==='') ? null : value;

        object[key] = value;
    });

    if(data){

          return  axios({
              method: 'put',
              url: url,
              data: url.includes('/admin/ikea-sizes') ? object : data,
              config: { headers: { 'Content-Type': 'application/json' }}
            }).then(result=>{
              if(button){
                  console.log('innerText',innerText);
                  button.innerText = 'Save';
                  button.disabled = false;
              }
              return result;
          })
              .catch(error => {
                  if(button){
                      button.innerText = innerText;
                      button.disabled = false;

                  }
                  return dispatch(handleApiError(error))
              });

    }
    event.preventDefault();
};

export const sendPost = (url, data, method="post",config) => dispatch => {


    if(!config){
        config = {headers: { 'Content-Type': 'application/json' }}
    }
        return  axios({
            method:method ,
            url: url,
            data: data,
            config: config
        })
            .catch(error => {

                return dispatch(handleApiError(error))
            });

};



export const setTableInfo = info => {
    return {
        type: SET_TABLE_INFO,
        payload: info
    }
};
export const setFormInfo = info => {
    return {
        type: SET_FORM_INFO,
        payload: info
    }
};
export const setPartners = data => {
    return {
        type: SET_PARTNERS,
        payload: data
    }
};

export const setViewInfo = info => {
    return {
        type: SET_VIEW_INFO,
        payload: info
    }
};
export const checkIsAdmin=()=>{
    let authorized = false;
    if(typeof(Storage) !== "undefined"){
        if(localStorage.jwtToken) {
            const decoded = jwt_decode(localStorage.jwtToken);
            authorized = true;
            const currentTime = Date.now() / 1000;
            if(decoded.exp < currentTime) {
                authorized = false;
            }
        }
    }
    return authorized;
};

export const handleApiError=(error) => dispatch=>{


    if (error.response) {
        // Request made and server responded
        let obj = {};
        let errors =  error.response ? error.response.data : (error.message ? error.message.errors : (error.request ? error.request : error))
        obj['message'] = errors.errors || errors;
        obj['type'] = 'error';
        obj['timeout'] = 3000;
        dispatch( {
            type: SET_NOTIFICATION,
            payload: obj
        });

        dispatch(
            {
                type: SET_API_STATUS_CODE,
                payload: error.response.status
            },
            );
        dispatch({
            type: SET_API_ERRORS,
            payload: error.response ? error.response.data : (error.message ? error.message : (error.request ? error.request : error))
        });
        return error.response
    } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    dispatch({
        type: SET_API_ERRORS,
        payload: error.response ? error.response.data : (error.message ? error.message : (error.request ? error.request : error))
    });
};
