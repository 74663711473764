import {store} from "../store";
import {getInfo} from "./admin";
import {SET_API_ERRORS, SET_CURRENCIES, SET_CURRENT_CURRENCY, SET_GST} from "./types";
import {setGst} from "./cart";
import {findObjectInArrayByKey} from "../functions/main";



const ApiUrl = process.env.REACT_APP_API_SERVER_URL;



export const getCurrencies= () => dispatch => {
    const ApiUrl = process.env.REACT_APP_API_SERVER_URL;
    let url =  ApiUrl+'/currencies/';

    dispatch(getInfo(url))
        .then(res=>{
            if(res.errors){
                console.log(res.errors);
            }else{
                if(res ){
                    dispatch(setCurrencies(res));

                    let currency = process.env.REACT_APP_PAYMENT_CURRENCY.toLowerCase();

                    if(typeof(Storage) === "undefined" || !localStorage.currentCurrency){
                        if(res.rows){
                            let findCurrency = findObjectInArrayByKey(res.rows,'code',currency);

                            setCurrency(findCurrency)
                        }
                    }else{
                        dispatch(setCurrentCurrency(JSON.parse(localStorage.currentCurrency)));
                    }
                }
            }
        })
        .catch(err=>{
            dispatch({
                type: SET_API_ERRORS,
                payload: err.response ? err.response.data : err
            });

        });
};
export const setCurrencies = (payload) => {
    return {
        type: SET_CURRENCIES,
        payload: payload
    }
};



export const setCurrency = (currency) => {
    store.dispatch(setCurrentCurrency(currency));
    if(typeof localStorage!=='undefined'){
        localStorage.setItem('currentCurrency', JSON.stringify(currency));
    }
};
export const setCurrentCurrency = (payload) => {
    return {
        type: SET_CURRENT_CURRENCY,
        payload: payload
    }
};
