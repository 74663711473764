import {SET_ADMIN_PREVIOUS_URL, SET_CURRENT_CUSTOMER, SET_CURRENT_USER, SET_USER_PREVIOUS_URL,SET_ACCOUNT,SET_SAVED_CARDS} from '../actions/types';
import isEmpty from '../validation/is-empty';

const initialState = {
    isAuthenticated: false,
    isCustomerAuthenticated: false,
    user: {},
    customer: {},
    account: {},
    saved_cards: [],
    previousUrl : false,
    previousAdminUrl : false,
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case SET_CURRENT_CUSTOMER:
            return {
                ...state,
                isCustomerAuthenticated: !isEmpty(action.payload),
                customer: action.payload
            };
        case SET_ACCOUNT:
            return {
                ...state,
                account: action.payload
            };
        case SET_SAVED_CARDS:
            return {
                ...state,
                saved_cards: action.payload
            };
        case SET_USER_PREVIOUS_URL:
            return {
                ...state,
                previousUrl: action.payload
            };
        case SET_ADMIN_PREVIOUS_URL:
            return {
                ...state,
                previousAdminUrl: action.payload
            };
        default:
            return state;
    }
}
