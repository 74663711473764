
import {
    SET_ROOM,
    SET_TYPE,
    SET_KIT,
    SET_PRICE,
    SET_API_ERRORS,
    SET_CALC_ROOMS,
    SET_CALC_CATEGORIES,
    SHOW_PRICE_BUTTON,
    SET_CALC_OPTIONS,
    SET_CABINET,
    SET_MINICART, SET_CALC_CATEGORY
} from './types';
import axios from "axios";


const apiUrl = process.env.REACT_APP_API_URL || process.env.NEXT_PUBLIC_API_URL;

export const setRoom = kit => ({ type: SET_ROOM, payload: kit.room });
export const setType = kit => ({ type: SET_TYPE, payload: kit.itemType });
export const setCalcCategory = kit => ({ type: SET_CALC_CATEGORY, payload: kit.calcCategory });
export const setKit =  kit => ({ type: SET_KIT,  payload: kit.kitItem });
export const setPrice =  kit => ({ type: SET_PRICE,  payload: kit.price });
export const setCabinet =  kit => ({ type: SET_CABINET,  payload: kit.cabinet });
export const setMiniCart =  kit => ({ type: SET_MINICART,  payload: kit.miniCart });

export const getCalcRooms = (filterroom) => dispatch => {
    filterroom = (filterroom!== undefined && (typeof filterroom !=='object')) ? filterroom : '';

    return axios.get(apiUrl+'/kit/api/room?fields=id,name,big_image&extraFields=id,name,alias,link,path,big_image,small_image&expand=kitCategories'+filterroom )

        .then(res => {
            const room = res.data;
            dispatch(setCalcRooms(room));
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err.response ? err.response.data : err
            });
        });
};
export const getCalcCategories = () => dispatch => {

    return axios.get(apiUrl+'/kit/api/calc-category?fields=id,name,slug,image,base,clickable&sort=-clickable' )

        .then(res => {
            const categories = res.data;

             return dispatch(setCalcCategories(categories));
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: SET_API_ERRORS,
                payload: err.response ? err.response.data : err
            });
        });
};


export const setCalcRooms = room => {
    return {
        type: SET_CALC_ROOMS,
        payload: room
    }
};

export const setCalcCategories = categories => {
    return {
        type: SET_CALC_CATEGORIES,
        payload: categories
    }
};
export const showPriceButton = state => {
    return {
        type: SHOW_PRICE_BUTTON,
        payload: state
    }
};
export const setCalcOptions = state => {
    return {
        type: SET_CALC_OPTIONS,
        payload: state
    }
}
;

