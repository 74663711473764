import axios from 'axios';
import setAuthToken from '../setAuthToken';
import jwt_decode from 'jwt-decode';
import {SET_CURRENT_USER, SET_CURRENT_CUSTOMER, SET_USER_PREVIOUS_URL, SET_API_ERRORS,SET_ADMIN_PREVIOUS_URL,SET_ACCOUNT,SET_SAVED_CARDS} from './types';
import {formSubmit, setNotification, toogleLoginModal} from "./init";
import {getInfo, handleApiError} from "./admin";
import {groupArrayBy} from "../functions/main";

export const loginUser = (user,props) => dispatch => {
    axios.post('/users/login', user)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
            console.log('props',props);

            dispatch(logoutCustomer(false));

            if(props.auth.previousAdminUrl){
                props.history.push(props.auth.previousAdminUrl);
            }else{
                props.history.push('/admin');
            }


        })
        .catch(err => {
            dispatch({
                type: SET_API_ERRORS,
                payload: err.response.data
            });
        });
}



export const logoutUser = (history) => dispatch => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    if(history){

            let previousUrl = history.location.pathname+history.location.hash;
            dispatch(setPreviousAdminUrl(previousUrl));
        history.push('/login');
    }else{
        window.location.href = '/login'
    }
};


export const logoutCustomer = (history) => dispatch => {
    localStorage.removeItem('customerToken');
    dispatch(setCurrentCustomer({}));
    if(history){
        let previousUrl = history.location.pathname+history.location.hash;
        dispatch(setPreviousUrl(previousUrl));
        history.push('/?login=true');
    }else{
        /**
         * Do not uncomment mazafuckers, until rewrite to normal!!! Tests fails with this
         */
        // window.location.href = '/?login=true'
    }
};

export const setUserFromLocalStorage = () => dispatch => {
    if(typeof(Storage) !== "undefined"){
        if(localStorage.jwtToken) {
            const decoded = jwt_decode(localStorage.jwtToken);
            const currentTime = Date.now() / 1000;
            if(decoded.exp < currentTime) {
                dispatch(logoutUser());
            }else{
                dispatch(setCurrentUser(decoded));
                setAuthToken(localStorage.jwtToken);
            }
        }
    }

};
export const setCustomerFromLocalStorage = () => dispatch => {
    if(typeof(Storage) !== "undefined"){
        if(localStorage.customerToken) {
            const decoded = jwt_decode(localStorage.customerToken);
            const currentTime = Date.now() / 1000;
            if(decoded.exp < currentTime) {
                dispatch(logoutCustomer());
            }else{
                dispatch(setCurrentCustomer(decoded));
                setAuthToken(localStorage.customerToken);
            }
        }
    }

};


export const register=(e,data=false,props,setState,action=false)=>dispatch=>{
    setState({errors:false});
    setState({submitButtonDisable: true});
    dispatch(formSubmit(e,'put',data,action)).then(res=>{

        setState({submitButtonDisable: false});
        if(res.data.errors){
            setState({errors:res.data.errors})
        }else{
            setState({success:res.data.success});
            setState({modalShow: true});
            if(res.data.token){
                dispatch(signCustomer(res.data.token,props));
            }else{
                // setTimeout(function () {
                //     // props.history.push('/?login=true');
                //     // dispatch(toogleLoginModal(false))
                // },1500);

                if(props.previousUrl){
                    props.history.push(props.previousUrl);
                }

            }

        }
    }).catch(error=>{
        setState({submitButtonDisable: false});
        setState({errors:((error.response && error.response.data) ? error.response.data.errors : error)})
    });
};
export const login=(e,data=false,props,setState,action=false)=>dispatch=>{
    setState({errors:false});
    setState({success:false});
    setState({submitButtonDisable: true});
    dispatch(formSubmit(e,'post',data,action)).then(res=>{

        setState({submitButtonDisable: false});
        if(res.data.errors){
            setState({errors:res.data.errors})
        }else{
            // setState({success:res.data});
            dispatch(setNotification('Login Successful', 'success', 5000));
            const { token } = res.data;
            dispatch(signCustomer(token,props));
        }

    }).catch(error=>{

        setState({submitButtonDisable: false});
        setState({errors:((error.response && error.response.data) ? error.response.data.errors : error)})
    });
};

/**
 *
 * @param customer
 * @param history
 * @returns {function(...[*]=)}
 */
export const getAccountInfo=({customer,history=false})=>dispatch=>{
    let authorized = false;
    if(typeof(Storage) !== "undefined"){
        if(localStorage.customerToken) {
            const decoded = jwt_decode(localStorage.customerToken);
            authorized = true;
            const currentTime = Date.now() / 1000;
            if(decoded.exp < currentTime) {
                authorized = false;
            }else{
                setAuthToken(localStorage.customerToken);
            }
        }
    }
    if(!authorized){
        dispatch(logoutCustomer(history));
    }

    const ApiUrl = process.env.REACT_APP_API_SERVER_URL;
    dispatch(
        getInfo('/customers/'+customer.id))
        .then(res=>{
            if(res.status===401){
               dispatch(logoutCustomer(history));
            }

            if(!res.errors){
                res.customer_roles = res.customer_roles ? groupArrayBy(res.customer_roles, 'name') : false;
                dispatch(setAccount(res));
                dispatch(getInfo( ApiUrl+'/orders/customer?status=saved_cart'))
                    .then(res=>{
                        dispatch(setSavedCards(res));
                    });
            }
        })
        .catch(error=>{
            dispatch(handleApiError(error))
        });
};


export const signCustomer=(token,props,success)=>dispatch=>{

    if(token){
        console.log('token',token);
        setAuthToken(token);
        localStorage.setItem('customerToken', token);
        const decoded = jwt_decode(token);
        dispatch(getAccountInfo({customer:decoded}));
        dispatch(setCurrentCustomer(decoded));
    }

    dispatch(toogleLoginModal(false));

    if(!props || !props.history)
        return;


    if(props.previousUrl){
        props.history.push(props.previousUrl,{
            login:true
        });
    }else{
        if(props.history.location.pathname.indexOf('refund') !== -1 ){
            return;
        }

        if(props.history.location.pathname.indexOf('calculator') === -1 &&
            props.history.location.pathname.indexOf('cart') === -1 &&
            props.history.location.pathname.indexOf('planner') === -1
        ){

            props.history.push('/account-information',{
                login:true,
                message: success,
            });
        }else{
            if(typeof window !== 'undefined'){
                window.scrollTo(0, document.body.scrollHeight);
            }
        }
    }
};

export const setAccount = decoded => {
    return {
        type: SET_ACCOUNT,
        payload: decoded
    }
};
export const setSavedCards = decoded => {
    return {
        type: SET_SAVED_CARDS,
        payload: decoded
    }
};
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
};

export const setCurrentCustomer = decoded => {
    return {
        type: SET_CURRENT_CUSTOMER,
        payload: decoded
    }
};
export const setPreviousUrl = decoded => {
    return {
        type: SET_USER_PREVIOUS_URL,
        payload: decoded
    }
};
export const setPreviousAdminUrl = decoded => {
    return {
        type: SET_ADMIN_PREVIOUS_URL,
        payload: decoded
    }
};
