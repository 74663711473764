import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter,
}from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'react-responsive-modal/styles.css';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from './app';
import Provider from "react-redux/es/components/Provider";
import {store} from "./store";

import './styles/style/style.css';
import './styles/style/home_reviews.css';
import './styles/style/home_slider.scss';
import './styles/style/font-awesome-4.7.0/scss/font-awesome.scss';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-input-range/lib/css/index.css';
import { loadableReady } from '@loadable/component'
import axios from "axios";
import {SET_API_ERRORS} from "./actions/types";
import {setGalleries} from "./actions/init";
import {Redirect} from "react-router-dom";
// import withHydrationOnDemand from "react-hydration-on-demand";
// const AppWithHydrationOnDemand = withHydrationOnDemand({
//     on: [["scroll", () => document]],
// })(App);

const Url = process.env.REACT_APP_SERVER_URL || process.env.NEXT_PUBLIC_SERVER_URL;
const ApiServer = process.env.REACT_APP_API_SERVER_URL || process.env.NEXT_PUBLIC_API_SERVER_URL;
axios.get(Url + ApiServer + '/get-redirects-list')
    .then(res => {
        let path = window.location.pathname;
        let redirects = res.data;

        let redirectPath = redirects[path]

        if (typeof redirectPath !== 'undefined' && redirectPath.length) {
            // todo: лучше переписать с location на react redirect
           document.location = redirectPath;
        }
    });

const reduxStore =
       store;

loadableReady(() => {
    ReactDOM.hydrate((
        <BrowserRouter>
            <Provider store = { reduxStore }>
                <App/>
            </Provider>
        </BrowserRouter>
    ), document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
