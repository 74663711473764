import {SET_CURRENCIES,SET_CURRENT_CURRENCY} from '../actions/types';


const initialState = {
    currencies: [],
    currentCurrency: false,
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_CURRENCIES:
            return {
                ...state,
                currencies: action.payload,
            };
        case SET_CURRENT_CURRENCY:
            return {
                ...state,
                currentCurrency: action.payload,
            };
        default:
            return state;
    }
}
