import {store} from "../store";
import {clearCart} from "../actions/cart";
import {logoutCustomer} from "../actions/authentication";
import React from "react";

export const addToCartAnimation = (imageId, imageWidthId) => {


    if(typeof document !=='undefined'){
        let productImages = document.getElementById(imageId);
        let productImagesWidth = document.getElementById(imageWidthId).offsetWidth;
        let firstProductImageSrc = productImages.src;
        let firstProductImagePositionTop = productImages.getBoundingClientRect().top;
        let firstProductImagePositionLeft = productImages.getBoundingClientRect().left;

        let animateImage = document.createElement('img');
        animateImage.className = 'animate-image';
        animateImage.src = firstProductImageSrc;
        if(isMobile()) {
            animateImage.style.top = '10%';
            animateImage.style.left = '0px';
        } else {
            animateImage.style.top = firstProductImagePositionTop + 'px';
            animateImage.style.left = firstProductImagePositionLeft + 'px';
        }

        animateImage.style.width = productImagesWidth + 'px';
        document.getElementById('animateImage').appendChild(animateImage);

        setTimeout(function () {
            animateImage.classList.add('animate');
        }, 50);

        setTimeout(function () {
            animateImage.remove();
        }, 1500);
    }

};

export const customerLogout = (e, history) => {
    e.preventDefault();
    store.dispatch(clearCart());
    store.dispatch(logoutCustomer(history));
};

export const formatName = (name) => {
    if(!name){
        return name;
    }

    name = name.replace(/[_\-\.]/gi," ");
    // name = name.charAt(0).toUpperCase() + name.slice(1);
    name = name.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    return name;
};

export const isTouch = () => {
   if(typeof document !== 'undefined'){
       if(document.documentElement.classList.contains("touchevents")){
           return true;
       }
   }
   return false;
};


export const userDevice = () => {


    if(typeof window !== 'undefined'){
        let intViewportWidth = window.innerWidth;
        if (intViewportWidth > 767 && intViewportWidth < 1200) {
            return 'tablet';
        } else if (intViewportWidth <= 767) {
            return 'mobile';
        } else {
            return 'desktop';
        }
    }
    return 'desktop';
};

export const isMobile = () => {
    return userDevice() !== 'desktop' || isTouch();
};


export function formatBackendPrice(price,currencyValue = 1) {
    price =  parseFloat(price)*currencyValue;
    return formatPrice(price,false,false,false,false)
}


export function getCurrency() {

    let currencySymbol = false;
    if(typeof Storage !=='undefined'){
        if (localStorage.currentCurrency !== undefined) {
            const currentCurrency = JSON.parse(localStorage.currentCurrency);
            if(currentCurrency && currentCurrency.code){
                currencySymbol = currentCurrency.symbol
            }
        }
    }

    return currencySymbol;


}


/**
 *
 * @param price
 * @param front
 * @param showCurrency
 * @param currencyHtml
 * @param convertCurrency
 * @param fractions
 * @returns {string}
 */
export function formatPrice(price,front=true,showCurrency=false,currencyHtml=true,convertCurrency=true,fractions=2, isServer=false) {
    let currencySymbol = false;
    if(!price)
        price=0;

    if(typeof Storage !=='undefined'){
        if (localStorage.currentCurrency !== undefined) {
            const currentCurrency = JSON.parse(localStorage.currentCurrency);
            if(currentCurrency && currentCurrency.code){
                if(convertCurrency){
                    price = parseFloat(price)*currentCurrency.value;
                    if(currentCurrency.extra && currentCurrency.extra.indexOf('%')!==-1){
                        price = price+(price*parseInt(currentCurrency.extra)/100)    ;

                    }
                }
                currencySymbol = currentCurrency.symbol
            }
        }
    }

    let priceResult = front?  parseFloat(price).toFixed(fractions).replace(/\d(?=(\d{3})+\.)/g, '$&,') : parseFloat(price).toFixed(fractions)


    if(!currencySymbol && isServer){
        currencySymbol = 'CAD $ '
    }

    if(currencySymbol && showCurrency){
        priceResult =currencyHtml
            ?
            <React.Fragment><span className="currency">{currencySymbol}</span><span className="number">{priceResult}</span></React.Fragment>
            : currencySymbol+priceResult
    }

    return priceResult;
}

export function formatPriceForGtm(price, currency) {
    return parseFloat(price).toFixed(2) + ' ' + currency;
}

export function htmlEncode(html) {
    html = html.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
        return '&#'+i.charCodeAt(0)+';';
    });
    return html;
}

export function is_touch_device() {

    if(typeof window ==='undefined' || typeof navigator ==='undefined')
        return false;

    return !!('ontouchstart' in window        // works on most browsers
        || navigator.maxTouchPoints);       // works on IE10/11 and Surface
}


export function getFullImageName(filename) {


    let ext = filename.substr(filename.lastIndexOf('.') + 1);

    return filename.replace(`.${ext}`,`-full.${ext}`);

}

export function getLetterByPosition(pos){

    let alphabet = "abcdefghijklmnopqrstuvwxyz";
    alphabet = alphabet.split('');

        return alphabet[pos] || alphabet[0];
}
