import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

import {createWrapper} from 'next-redux-wrapper';


const inititalState = (typeof window !== "undefined" && typeof window.REDUX_DATA !== "undefined") ? window.REDUX_DATA  : {};
export const store = createStore(
    rootReducer,
    inititalState,
    compose(applyMiddleware(thunkMiddleware)
        // ,
        // window.__REDUX_DEVTOOLS_EXTENSION__&& window.__REDUX_DEVTOOLS_EXTENSION__()
    )
) ;


export default ( initialState ) =>
    createStore( rootReducer, initialState, applyMiddleware( thunkMiddleware ) );





const makeStore = () => store;

export const wrapper = createWrapper(makeStore, {debug: false});


// export default store;

