import {
    SET_ALIASES,
    SET_CATALOG,
    SET_CATEGORY,
    SET_CONTACTS,
    SET_SOCIAL,
    SET_PAGE,
    SET_PAGE_START,
    SET_PRODUCT_LIST,
    SET_VIEWED_PRODUCTS,
    SET_HOME_GALLERIES,
    SET_ABOUT_PAGE,
    SET_PRODUCT_SERIES,
    SET_STATIC_PAGE,
    TOOGLE_LOGIN,
    SET_PROMO_CODE,
    SET_MENU,
    SET_COUNT_ORDERS,
    ADD_PRODUCT_LIST,
    SET_GOOGLE_REVIEWS,
    SET_DISCOUNT_AMOUNTS,
    SET_STORE_DATA,
    SET_CATALOG_CATEGORIES,
    SET_CALCULATE_CATEGORIES,
} from '../actions/types';

const initialState = {
    aliases:false,
    page:false,
    staticPage:false,
    pageStart:false,
    catalog:false,
    category:false,
    products:false,
    viewed_products:false,
    contacts:false,
    social:false,
    storeData:false,
    galleries:false,
    about:false,
    product_series:false,
    loginShow:false,
    menu:false,
    countOrders:false,
    googleReviews:false,
    discountAmounts:false,
    promoCode:false,
    catalogCategories: false,
    calculateCategories: false,
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_ALIASES:
            return {
                ...state,
                aliases: action.payload
            };
        case SET_PROMO_CODE:
            return {
                ...state,
                promoCode: action.payload
            };
        case SET_DISCOUNT_AMOUNTS:
            return {
                ...state,
                discountAmounts: action.payload
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case SET_STATIC_PAGE:
            return {
                ...state,
                staticPage: action.payload
            };
        case SET_PAGE_START:
            return {
                ...state,
                pageStart: action.payload
            };
        case SET_CATALOG:
            return {
                ...state,
                catalog: action.payload
            };
        case SET_CATEGORY:
            return {
                ...state,
                category: action.payload
            };
        case SET_CATALOG_CATEGORIES:
            return {
                ...state,
                catalogCategories: action.payload
            };
        case SET_CALCULATE_CATEGORIES:
            return {
                ...state,
                calculateCategories: action.payload
            };
        case SET_PRODUCT_LIST:
            return {
                ...state,
                products: action.payload
            };
        case SET_VIEWED_PRODUCTS:
            return {
                ...state,
                viewed_products: action.payload
            };
        case SET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            };
        case SET_SOCIAL:
            return {
                ...state,
                social: action.payload
            };
        case SET_STORE_DATA:
            return {
                ...state,
                storeData: action.payload
            };
        case SET_HOME_GALLERIES:
            return {
                ...state,
                galleries: action.payload
            };
        case SET_ABOUT_PAGE:
            return {
                ...state,
                about: action.payload
            };
        case SET_PRODUCT_SERIES:
            return {
                ...state,
                product_series: action.payload
            };
        case TOOGLE_LOGIN:
            return {
                ...state,
                loginShow: action.payload
            };
        case SET_MENU:
            return {
                ...state,
                menu: action.payload
            };
        case SET_COUNT_ORDERS:
            return {
                ...state,
                countOrders: 165+action.payload
            };
        case SET_GOOGLE_REVIEWS:
            return {
                ...state,
                googleReviews: action.payload
            };
        case ADD_PRODUCT_LIST:
            let products = [...state.products.rows, ...action.payload.rows];
            products = {...state.products, rows: products};
            return {
                ...state,
                products: products
            };
        default:
            return state;
    }
}
