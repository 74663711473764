import {SET_FILTERS} from '../actions/types';


const initialState = {
    filters: false,
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
}