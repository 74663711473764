import {SET_CART_PRODUCTS, SET_GST, SET_ADD_TO_CART_BUTTON_TEXT, SET_SHIPPING,SET_ADMIN_SAVED_CART} from '../actions/types';


const initialState = {
    products: false,
    total: false,
    quantityTotal: false,
    gst: false,
    shipping: [],
    addToCartButtonText: 'Add to Cart',
    adminSavedCardCreate:false
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_CART_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                total: action.payload.total,
                quantityTotal: action.payload.quantityTotal
            };

        case SET_GST:
            return {
                ...state,
                gst: action.payload,
            };
         case SET_SHIPPING:
            return {
                ...state,
                shipping: action.payload,
            };
         case SET_ADMIN_SAVED_CART:
            return {
                ...state,
                adminSavedCardCreate: action.payload,
            };
        case SET_ADD_TO_CART_BUTTON_TEXT:
            return {
                ...state,
                addToCartButtonText: action.payload ? action.payload : 'Add to Cart',
            };

        default:
            return state;
    }
}
