import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import kitReducer from './kitReducer';
import authReducer from './authReducer';
import adminReducer from './adminReducer';
import initReducer from './initReducer';
import filterReducer from './filterReducer';
import cartReducer from './cartReducer';
import currencyReducer from './currencyReducer';
export default combineReducers({
    errors: errorReducer,
    kit: kitReducer,
    auth: authReducer,
    init: initReducer,
    admin: adminReducer,
    filters: filterReducer,
    cart: cartReducer,
    currency: currencyReducer,
});
