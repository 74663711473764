import {SET_API_ERRORS, SET_API_STATUS_CODE, SET_NOTIFICATION} from '../actions/types';

const initialState = {
    api:false,
    statusCode:false,
    notification: false,
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_API_ERRORS:
            return {
                ...state,
                api: action.payload
            };
        case SET_API_STATUS_CODE:
            return {
                ...state,
                statusCode: action.payload
            };
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            };
        default:
            return state;
    }
}
