import React, {Component} from 'react';
import loadable from '@loadable/component'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {store} from "./store";
import {getContacts, getMenu, getPageInfo, getSocial, setPage} from "../src/actions/init";

const Category = loadable(() => import('./components/site/Category'));
const Product = loadable(() => import('./components/site/Product'));
const Page = loadable(() => import('./components/site/Page'));
const NotFound = loadable(() => import('./components/site/NotFound'));

class RouteHelper extends Component {

    componentWillUpdate(nextProps, nextState, nextContext) {

        if(this.props.match.params.alias !== nextProps.match.params.alias
        )
        {
            store.dispatch(getPageInfo(nextProps.match.params.alias));
        }
    }

    componentWillUnmount() {
        store.dispatch(setPage(false));
    }

    componentDidMount() {
        if(!this.props.init.page){
            store.dispatch(getPageInfo(this.props.match.params.alias));
        }
    }

    isCategory()
    {
        let allProps = this.props;
        let parentRoute = allProps.parentRoute;
        let pathname = allProps.location.pathname;

        return pathname.includes(parentRoute);
    }

    render() {
        let allProps = this.props;

        let page = allProps.init.page;
        let types = {
            Category: Category,
            Product: Product,
            Page: Page,
            NotFound: NotFound,
        };
        let MatchComponent = false;
        let MatchObject = null;

        if (!this.isCategory()) {
            types.Category = NotFound;
        }

        if (page !== false){
            if (page !== undefined && page.type && page.result !== null) {
                MatchComponent = types[page.type];
                MatchObject = page.result
            } else {
                MatchComponent = null;
            }
        }

        return (
            (MatchComponent!==false) ? (
                (MatchComponent!==null)
                    ?
                    <MatchComponent {...this.props} matchObject={MatchObject}/>
                    :
                    <NotFound/>
            ) : null

        )
    }
}



RouteHelper.serverFetch = [getPageInfo,getContacts,getMenu,getSocial]; // static declaration of data requirements
RouteHelper.propTypes = {
    init: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    init: state.init,
});
export default connect(mapStateToProps)(RouteHelper);
