import {SET_TABLE_INFO,SET_FORM_INFO,SET_VIEW_INFO,SET_PARTNERS} from '../actions/types';

const initialState = {
    tableInfo:false,
    formInfo:false,
    viewInfo:false,
    partners:false,
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_TABLE_INFO:
            return {
                ...state,
                tableInfo: action.payload
            };
        case SET_FORM_INFO:
            return {
                ...state,
                formInfo: action.payload
            };
        case SET_VIEW_INFO:
            return {
                ...state,
                viewInfo: action.payload
            };
        case SET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            };
        default:
            return state;
    }
}
