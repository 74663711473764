export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ROOM = 'SET_ROOM';
export const SET_TYPE = 'SET_TYPE';
export const SET_KIT = 'SET_KIT';
export const SET_PRICE = 'SET_PRICE';
export const SET_ALIASES = 'SET_ALIASES';
export const SET_CALC_CATEGORIES = 'SET_CALC_CATEGORIES';
export const SET_CALC_ROOMS = 'SET_CALC_ROOMS';
export const SET_CATALOG = 'SET_CATALOG';
export const SET_PAGE = 'SET_PAGE';
export const SET_PAGE_START = 'SET_PAGE_START';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_TABLE_INFO = 'SET_TABLE_INFO';
export const SET_VIEW_INFO = 'SET_VIEW_INFO';
export const SET_FORM_INFO = 'SET_FORM_INFO';
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const SET_VIEWED_PRODUCTS = 'SET_VIEWED_PRODUCTS';
export const SET_DISCOUNT_AMOUNTS = 'SET_DISCOUNT_AMOUNTS';
export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_HOME_GALLERIES = 'SET_HOME_GALLERIES';
export const SET_CATALOG_CATEGORIES = 'SET_CATALOG_CATEGORIES';
export const SET_CALCULATE_CATEGORIES = 'SET_CALCULATE_CATEGORIES';
export const SET_ABOUT_PAGE = 'SET_ABOUT_PAGE';
export const SET_PRODUCT_SERIES = 'SET_PRODUCT_SERIES';
export const SET_CART_PRODUCTS = 'SET_CART_PRODUCTS';
export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const SET_STATIC_PAGE = 'SET_STATIC_PAGE';
export const SHOW_PRICE_BUTTON = 'SHOW_PRICE_BUTTON';
export const SET_MATERIALS = 'SET_MATERIALS';
export const SET_CALC_OPTIONS = 'SET_CALC_OPTIONS';
export const TOOGLE_LOGIN = 'TOOGLE_LOGIN';
export const SET_CABINET = 'SET_CABINET';
export const SET_MINICART = 'SET_MINICART';
export const SET_MENU = 'SET_MENU';
export const SET_CALC_CATEGORY = 'SET_CALC_CATEGORY';
export const SET_API_ERRORS = 'SET_API_ERRORS';
export const SET_API_STATUS_CODE = 'SET_API_STATUS_CODE';
export const SET_GST = 'SET_GST';
export const SET_ADD_TO_CART_BUTTON_TEXT = 'SET_ADD_TO_CART_BUTTON_TEXT';
export const SET_SHIPPING = 'SET_SHIPPING';
export const SET_ADMIN_SAVED_CART = 'SET_ADMIN_SAVED_CART';
export const SET_USER_PREVIOUS_URL = 'SET_USER_PREVIOUS_URL';
export const SET_ADMIN_PREVIOUS_URL = 'SET_ADMIN_PREVIOUS_URL';
export const SET_COUNT_ORDERS = 'SET_COUNT_ORDERS';
export const SET_GOOGLE_REVIEWS = 'SET_GOOGLE_REVIEWS';
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY';
export const ADD_PRODUCT_LIST = 'ADD_PRODUCT_LIST';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_PARTNERS = 'SET_PARTNERS';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_SAVED_CARDS = 'SET_SAVED_CARDS';
export const SET_SOCIAL = 'SET_SOCIAL';
export const SET_STORE_DATA = 'SET_STORE_DATA';
