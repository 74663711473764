import loadable from '@loadable/component'

// const Home = loadable(() => import('./components/site/Home'));
import Home from './components/site/Home';
import RouteHelper from './RouteHelper';


const Catalog = loadable(() => import('./components/site/Catalog'));
const Product = loadable(() => import('./components/site/Product'));
const ShoppingCart = loadable(() => import('./components/site/ShoppingCart'));
const Checkout = loadable(() => import('./components/site/Checkout'));
const Refund = loadable(() => import('./components/site/Refund'));
const OrderComplete = loadable(() => import('./components/site/OrderComplete'));
const Search = loadable(() => import('./components/site/Search'));
const AccountActivate = loadable(() => import('./components/site/modules/account/AccountActivate'));
const AccountInformation = loadable(() => import('./components/site/modules/account/AccountInformation'));
const Calc = loadable(() => import('./components/calc/Calc'));
const CalcCategories = loadable(() => import('./components/calc/CalcCategories'));
const Planner = loadable(() => import('./components/calc/Planner'));
const Samples = loadable(() => import('./components/site/Samples'));
const KitchenDesign = loadable(() => import('./components/kitchen'));
const KitchenSuccess = loadable(() => import('./components/kitchen/Success'));
export default [
    {
        path: "/",
        component: Home,
        exact: true,
        props:{
            parentRoute : '/calc'
        }
    },
    {
        path: "/catalog",
        component: Catalog,
        exact: true,
    },
    {
        path: "/catalog/:alias",
        component: RouteHelper,
        props:{
            parentRoute : '/catalog'
        },
        exact: false,
    },
    {
        path: "/account/activate/:token",
        component: AccountActivate,
        exact: true,
    },
    {
        path: "/account-information",
        component: AccountInformation,
        exact: true,
    },
    {
        path: "/product",
        component: Product,
        exact: true,
    },
    {
        path: "/cart",
        component: ShoppingCart,
        exact: true,
    },
    {
        path: "/cart/checkout",
        component: Checkout,
        exact: true,
    },
    {
        path: "/cart/checkout/success",
        component: OrderComplete,
        exact: true,
    },
    {
        path: "/refund/:type/:id",
        component: Refund,
        exact: true,
    },
    {
        path: "/search",
        component: Search,
        exact: true,
    },
    {
        path: "/calculator",
        component: Calc,
        exact: false,
        props:{
            parentRoute : '/calculator'
        }
    },
    {
        path: "/calc-categories",
        component: CalcCategories,
        exact: false,
        props:{
            parentRoute : '/calculator'
        }
    },
    {
        path: "/planner",
        component: Planner,
        exact: false,
    },
    {
        path: "/samples",
        component: Samples,
        exact: false,
    },

    {
        path: "/kitchen/success",
        component: KitchenSuccess,
        exact: true,
    },
    {
        path: "/kitchen",
        component: KitchenDesign,
        exact: true,
    },
    {
        path: "/kitchen/:url",
        component: KitchenDesign,
        exact: true,
    },
    {
        path: "/:alias",
        component: RouteHelper,
        exact: false,
    },
];

